img .letter {
    width: 100% !important;
    //height:  !important;
    }

@media print {
    .no-print {display: none !important}
}
.docwidth {
    width: 900px !important;
    //min-width: 70vw !important;
    //max-width: ;
    //padding-left: 200px;
    //padding-right: 200px;
}