
#left-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#search-panel{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left-input-text{
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    display: flex;
    flex-wrap: wrap;
    margin: 8px;
}

.text-center{
    width: 100%;

    h5{
        color: rgba(10%, 10%, 10%, 0.85) ;
    }
}
@media (min-width: 1000px){
    .search-panel-message {
        width: 70% !important;
    }
}

.warning {
    font-size: medium; //small or medium?
    background-color: #ebf5f6 !important;

}

.hyperlinks {
    color:  #075290;

    &:hover{
        text-decoration: underline !important;
    }
    &:focus{
        text-decoration: underline !important;
    }
}
