.panel{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    border-radius: 0.75rem;
}


.img-fluid {
    max-width: 15% !important;
    //max-height: 55px;

    //.img-thumbnail{
        height: 55px !important;
    //}
}

.content{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}


.card-footer{
    font-size: 0.8em;
}

.card-body{
    padding: 1rem;
}

.accordion .btn-link {
    width: 100%;
    text-align: left;
}

.map-address {
    display: none !important;
}

@media print {
    .map-address{ 
        display: flex !important;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        font-size: small;
        p{
            padding-left: 3vw;
            padding-right: 3vw;
            margin-bottom: 1px;
            margin-top: 1px;
            
        }
        
         
        a {
    
            padding-left: 3vw;
            padding-right: 3vw;
            margin-bottom: 1px;
            margin-top: 1px;
        
    
        
        }
    
        .contact{
            width: 100%;
            padding-right: 1px;
    
            a {
                color: #075290;
            }
        }
    
        h7{ 
            padding-left:1vw;
            padding-right: 1vw;
            margin-top: 1vh; 
            margin-bottom: 0.5vh;
            font-weight: bold
          }
    
        .hdbox {
            background-color: #17a2b8;
    
        }

    }


  }
