#right-output{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.75rem;
    margin-left: 1vw;
    margin-right: 1vw;
    padding: 1rem;
    background-color: rgba(94%, 94%, 94%, 0.9);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
   
 /*   h3{
        padding: 2px;
        padding-right: 4px;
        padding-left: 4px;
        font-size: medium;
        text-align: center;
        justify-content: center;
        align-items: center;
      } */
}

.box {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 1vw;
    margin-right: 1vw;
}

#general-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

