#search-button{
    height: 37px;
    margin: 0px;
    font-size: 12px;
    padding: 8px;
}

.search-bar{
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    align-self: center;
}


@media (min-width: 1000px){
    #basic-navbar-nav .search-bar {
        position: absolute;
    }
}