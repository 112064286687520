/*
#main-body{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
*/

.background{
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: auto;
    height: auto;
    background-size: cover;
    //min-height: calc(100vh - 70px);
    height: 100vh;
}

.results{
    height: auto !important; //fixes display problem with IE but issue on search results pg. 
}

#left-side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2vw;
    margin-right: 2vw;
}

@media print {

    .collapse {
      display:block !important
    }

    .pagebreak  {

        page-break-before: always;
        
        }
  
  }

