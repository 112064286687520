#address {
    display: flex;
    align-content: center;
    flex-direction: column;
    font-size: small;

    p{
        padding-left: 3vw;
        padding-right: 3vw;
        margin-bottom: 1px;
        margin-top: 1px;
        
    }
    
     
    a {

        padding-left: 3vw;
        padding-right: 3vw;
        margin-bottom: 1px;
        margin-top: 1px;
    

    
    }

    .contact{
        width: 100%;
        padding-right: 1px;

        a {
            color: #075290;
        }
    }

    h7{ 
        padding-left:1vw;
        padding-right: 1vw;
        margin-top: 1vh; 
        margin-bottom: 0.5vh;
        font-weight: bold
      }

    .hdbox {
        background-color: #17a2b8;

    }
  }
  #address-box .card {

    border: 0;

    border-radius: 0;

    box-shadow: none;

}

 
#address-box .card-body {

padding:0px;

}

  .department-info-button {
    padding-left: 3vw;
    padding-right: 3vw;
    margin-bottom: 1px;
    margin-top: 1px; 
    text-transform: none !important;
    color: rgb(249, 249, 249) !important;
}

.justify-content-end{
    padding: 1vw;
}


#comments{
    display: flex;

    padding-left: 3vw;
    font-size: small;
    padding-right: 3vw;
    align-self: center;

    margin-bottom: 1px;
    margin-top: 1px;
}

#section-header{
    display: flex;
    align-self: center;
    color: rgba(10%, 10%, 12%, 0.9);
    margin-top: 0px;
    margin-bottom: 0px;
}

.right-address{
    display: flex;
    height: 80px;
    flex-direction: column;
    justify-content: space-between;
}