#hd-info-card {
    display: flex;
    //min-height: 530px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #007d91;
    color: rgb(255, 255, 255) !important;
    opacity: 0.99 !important;
    ;

    .card-footer{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-link{
        font-size: larger;
        color: rgb(252, 252, 252);
       // text-decoration: underline !important;


        &:hover{
            color: rgba(248, 248, 248, 0.95);
            text-decoration: underline !important;
        }
        &:focus{
            color: rgba(248, 248, 248, 0.95);
            text-decoration: underline !important;
        }
    }
}

#welcome-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .hyperlinks {
        color: rgb(253,253,253) !important;
    
    }
}

.link-button {
    text-align: center;
    .btn {
        border-radius: 1rem;
        color: rgb(253,253,253) !important;
        margin-bottom: 15px;
        width: auto; 
        margin-left: auto;
        margin-right: auto;   
    }
}

div#weblink-text {
    width: auto !important;
}

#indent {
    padding-left: 10px;
}

.department-sections {
    font-weight: bold;
    color: rgb(253,253,253) !important;
}

#department-buttons {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

small {
    font-size: 95% !important;
}