/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; } 
  */

@font-face {

    font-family: "Open Sans";
  
    font-style: normal;
  
    font-weight: 400;
  
    src: url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.eot);
  
    src: local("Open Sans Regular"), local("OpenSans-Regular"), url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.eot?#iefix) format("embedded-opentype"), url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.woff2) format("woff2"), url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.woff) format("woff"), url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.ttf) format("truetype"), url(https://www.cdc.gov/TemplatePackage/4.0/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans) format("svg")
  
  }

code {
  font-family: 'Open Sans-Regular', sans-serif;
}

body {
  font-family: "Open Sans-Regular", sans-serif;
}

p {
  font-family: "Open Sans-Regular", sans-serif;
  opacity: 0.9;
}

div {
  font-family: "Open Sans-Regular", sans-serif;
}

a {
  font-family: "Open Sans-Regular", sans-serif;
}

input {
  font-family: "Open Sans-Regular", sans-serif;
}

h1, h2, h4, h5, h6 {
  font-family: "Open Sans-Regular", sans-serif;
}

h3 {
  font-family: "Open Sans-Regular", sans-serif;
  color: rgb(10%, 10%, 10%);
  padding: 10px;
}

h1 {
  font-size: 1.5rem !important;
}

h2 {
  font-size: 1.3rem !important;
}

img {
width: 100%;
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #007d91;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
}
