
.help-buttons{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2vh;
    padding-bottom: 3vh;

    button {
        border-radius: 1rem !important;
        color: rgb(253,253,253) !important;
    }
}