.navbar-collapse{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-self: flex-end;
    color:  rgb(247,247,247) !important;
}



#nav-elements {
    background-color:#007d91;
    min-height: 70px;
    display: flex;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(252,252,252) !important;

    &:hover{
        color: rgba(235,235,235,0.95) !important;
        text-decoration: underline !important;
    }
    &:focus{
        color: rgba(235,235,235,0.95) !important;
        text-decoration: underline !important;
    }
 
}

.navbar-toggler{
    &:focus{
        background-color:rgba(230,230,230,0.1) !important;
    }
}
.search-bar{
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    align-self: center;
}

@media print {
    .fixed-top {
        position: relative !important;
    }
}